<template>
  <PanelTableMolecule
    :isLoading="$apollo.loading"
    :count="totalCount"
    :title="$tc('acquisition', 2) | capitalize"
    :columns="columns"
    :rows="rows"
    :pageIndex="pageIndex"
    :pageSize="pageSize"
    :createRouteName="'CreateProduct'"
    :selectedRow="selectedRow"
    :hasClearSearch="false"
    :useDarkStyle="true"
    :hasMargin="true"
    @pageNumberChange="pageNumberChange($event)"
    @reload="reload"
  />
</template>

<script>
import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'

import LIST_AQUISITIONS_QUERY from '#/graphql/acquisition/listAcquisitions.gql'

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  components: {
    PanelTableMolecule,
  },
  data() {
    return {
      acquisitions: [],
      totalCount: 0,
      defaultPage: 1,
      perPage: 25,
      selectedRow: undefined,
    }
  },
  computed: {
    pageIndex() {
      const i = parseInt(this.$route?.query.page)
      return !isNaN(i) ? i : this.defaultPage
    },
    pageSize() {
      return this.$route?.query.perPage ?? this.perPage
    },
    skip() {
      return (this.pageIndex - 1) * this.pageSize
    },
    columns() {
      const header = [
        {
          field: 'organization',
          key: 'organization',
          title: 'Organization',
          align: 'left',
          width: '10%',

          renderBodyCell: ({ row }) => {
            const to = { name: 'acquisition', params: { id: row.id } }
            return <router-link to={to}>{row?.organization?.name}</router-link>
          },
        },
        {
          field: 'tierLevel',
          key: 'tierLevel',
          title: 'Tier Level',
          align: 'center',
        },
        {
          field: 'status',
          key: 'status',
          title: 'Status',
          align: 'center',
        },
        {
          field: 'purchasedAt',
          key: 'purchasedAt',
          title: 'Purchased at',
          align: 'center',
        },
        {
          field: 'orderedAt',
          key: 'orderedAt',
          title: 'Ordered at',
          align: 'center',
        },
        {
          field: 'createdAt',
          key: 'createdAt',
          title: 'Created at',
          align: 'center',
        },
      ]
      return header
    },
    rows() {
      return this.acquisitions.map(m => ({
        ...m,
        tierLevel: m.productTierLevel.tierLevel,
      }))
    },
  },
  methods: {
    pageNumberChange(page) {
      if (this.pageIndex !== page) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: page === this.defaultPage ? undefined : page,
          },
        })
      }
    },
    reload() {
      this.$apollo.queries.acquisitions.refetch()
    },
  },
  apollo: {
    acquisitions: {
      query: LIST_AQUISITIONS_QUERY,
      variables() {
        return {
          take: this.pageSize,
          skip: this.skip,
          where: {
            productId: {
              equals: this.productId,
            },
          },
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.acquisitions = data?.acquisitions
        this.totalCount = data?.aggregateAcquisition?._count?._all || 0
      },
    },
  },
}
</script>
